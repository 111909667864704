

























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
    apiDistributionGradeAdd,
    apiDistributionGradeDetail,
    apiDistributionGradeEdit
} from '@/api/distribution/distribution'

@Component
export default class DistributionGradeEdit extends Vue {
    /** S Data **/
    identity: number | null = null // 当前编辑用户的身份ID  valid: mode = 'edit'
    roleList: Array<object> = [] // 角色的数据

    mode: any
    id: any
    flag: any = 1
    disabled: any = false

    // 添加管理员表单数据
    form: any = {
        name: '', //是	string	等级名称
        weights: '', //是	integer	等级级别 须大于1
        self_ratio: '', //是	decimal	自购佣金比例 1-100之间，保留2位小数
        first_ratio: '', //是	decimal	一级佣金比例 1-100之间，保留2位小数
        second_ratio: '', //是	decimal	二级佣金比例 1-100之间，保留2位小数
        update_relation: 1, //是	integer	升级关系 1-满足任意条件 2-满足全部条件
        update_condition: [
            {
                singleConsumptionAmount: '' //否	decimal	单笔消费金额
            },
            {
                cumulativeConsumptionAmount: '' //否	decimal	累计消费金额
            },
            {
                cumulativeConsumptionTimes: '' //否	integer	累计消费次数
            },
            {
                returnedCommission: '' //否	decimal	已结算佣金收入
            }
        ], //是	array	升级条件,至少传一个条件
        remark: '' //否	string	等级备注
    }
    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 请求发送
        console.log('form', this.form)
        if (
            Number(this.form.first_ratio) +
                Number(this.form.second_ratio) +
                Number(this.form.self_ratio) >
            100
        ) {
            this.$message.error('等级佣金总和不能超过100%')
            return
        }

        switch (this.mode) {
            case 'add':
                return this.GradeAdd()
            case 'edit':
                return this.GradeEdit()
        }
    }

    // 编辑
    GradeEdit() {
        this.form.update_condition = this.form.update_condition
        apiDistributionGradeEdit({ ...this.form }).then(() => {
            this.$router.go(-1)
        })
    }

    // 添加
    GradeAdd() {
        apiDistributionGradeAdd({ ...this.form }).then(() => {
            this.$router.go(-1)
        })
    }

    // 详情
    detail() {
        apiDistributionGradeDetail({ id: this.id })
            .then(res => {
                if (res.update_condition) {
                    const data = res.update_condition
                    const arr = [
                        {
                            singleConsumptionAmount:
                                data.singleConsumptionAmount
                        },
                        {
                            cumulativeConsumptionAmount:
                                data.cumulativeConsumptionAmount
                        },
                        {
                            cumulativeConsumptionTimes:
                                data.cumulativeConsumptionTimes
                        },
                        { returnedCommission: data.returnedCommission }
                    ]
                    res.update_condition = arr
                }
                this.form = res
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query

        if (query.mode) {
            this.mode = query.mode
        }
        if (query.flag) {
            this.flag = query.flag
        }
        if (query.disabled) {
            this.disabled = true
        }

        // 编辑模式：初始化数据
        if (this.mode === 'edit') {
            this.id = query.id
            this.detail()
        }
        // 获取角色列表
    }
    /** E Life Cycle **/
}
